import React from "react"

import Seo from "src/components/seo"
import Time from "src/components/time"

import * as styles from "./index.module.scss"

const IndexPage = () => {
  return (
    <div className={styles.top}>
      <Seo />

      <div className={styles.background}>
        <div></div>
      </div>

      <header className={styles.header}>
        <h1>YOSUKE URAKAMI</h1>
        <div>
          <a href="https://goo.gl/maps/sGf2rBQyRykU7J8H6" target="_blank" rel="noreferrer noopener">
            &gt; Zaō, Miyagi <Time />
          </a>
        </div>
        <div>
          <a href="mailto:yousukeurakami@gmail.com" target="_blank" rel="noreferrer noopener">
            &gt; Contact
          </a>
        </div>
      </header>

      <div className={styles.bottom}>
        <table>
          <tbody>
            <tr>
              <td className={styles.year} rowSpan={1}>
                Exhibition 2023
              </td>
              <td className={styles.date}>3.11 Sat 〜 3.13 Mon</td>
              <td className={styles.name}>
                <a href="https://www.leaf-mania.com/" target="_blank" rel="noreferrer noopener">
                  LEAFMANIA
                </a>
              </td>
              <td className={styles.city}>東京</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IndexPage
