import React, { useEffect, useState } from 'react'

const Time = () => {

    const [hours, setHours] = useState(null)

    const [minutes, setMinutes] = useState(null)

    const [seconds, setSeconds] = useState(null)


    useEffect(() => {

        const tick = () => {
            const date = new Date(Date.now() + 9 * 60 * 60 * 1000)
            setHours(date.getUTCHours())
            setMinutes(date.getUTCMinutes())
            setSeconds(date.getSeconds())
        }

        tick()

        setInterval(tick, 1000)
    }, [])

    if (hours !== null) {
        return (
            <span>{hours % 12}<span style={{ opacity: seconds % 2 }}>:</span>{('00' + minutes).slice(-2)} {hours < 12 ? `AM` : `PM`}</span>
        )
    } else {
        return null
    }
}

export default Time